/* Fix to improve CLS */
.carousel.promo.top-carousel {
	min-height: 250px;
}

/* Fix to improve LCP */
.top-carousel .splide {
	visibility: visible !important;
}

.carousel .splide {
	visibility: visible !important;
}

/* Fixes for Safari weird behavior on dark mode */
:has([data-theme='dark']) .ark-ui-topbar input::placeholder {
	color: var(--color-white);
}

:has([data-theme='dark']) .ark-ui-topbar-link {
	color: var(--color-white);
}

main {
	isolation: isolate;
}

body {
	overflow: hidden;
	overflow-x: hidden !important;
}

/* container for both notification bar and navigation bars */
.viewTopContainer {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 90;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	pointer-events: none;
}
.viewNavigationBars {
	width: 0;
	height: 100%;
	pointer-events: all;
}

body:has(header) main.gamepage {
	padding-top: 0;
}

body:has(.ark-ui-topbar.topbar) div.data-wrapper {
	padding-top: calc(var(--topbar-height) + var(--top-padding, 0));
}

body:has(.ark-ui-search-active) #sidebarToggle {
	pointer-events: none;
}

main {
	min-height: 100vh;
	margin: 0;
	padding-bottom: 5rem;
}
main.noPadding {
	padding-bottom: 0;
}

.sidebar-collapsed-hidden .ark-ui-sidebar.sidebar {
	visibility: hidden;
}
.sidebar-collapsed-hidden #sidebarToggle:checked ~ .ark-ui-sidebar.sidebar {
	visibility: visible;
}

.category-page > div:not(.carousels, .headers),
.tag-page > div:not(.carousels),
.search-page > div:not(.carousels, .headers) {
	margin: 2rem var(--grid-margins) 0 var(--grid-margins);
}

/* Mobile and Tablet styles */
body[data-device-type='mobile'] .tag-page > div:not(.carousels),
body[data-device-type='tablet'] .tag-page > div:not(.carousels) {
	margin: 2rem 0 0 0;
}
body[data-device-type='mobile'] .tag-page [class*='headers'] > details,
body[data-device-type='tablet'] .tag-page [class*='headers'] > details {
	width: calc(100% - var(--grid-margins));
	white-space: nowrap;
}
body[data-device-type='mobile'] .tag-page [class*='headers'] > details > summary > span,
body[data-device-type='tablet'] .tag-page [class*='headers'] > details > summary > span {
	display: inline;
}
body[data-device-type='mobile'] .tag-page div:not(.carousels)[class*='seo-panel'],
body[data-device-type='tablet'] .tag-page div:not(.carousels)[class*='seo-panel'] {
	margin-left: var(--grid-margins);
}
body[data-device-type='mobile'] .tag-page [class*='carousel'],
body[data-device-type='tablet'] .tag-page [class*='carousel'] {
	max-width: 100%;
	overflow-x: hidden;
	margin-top: 0.5rem;
}
body[data-device-type='mobile'] .tag-page [class*='styles_results'],
body[data-device-type='tablet'] .tag-page [class*='styles_results'] {
	padding: 0.25rem 0 0;
}
body[data-device-type='mobile']:has(div[class*='tag-page']) footer[class*='styles_footer__'],
body[data-device-type='tablet']:has(div[class*='tag-page']) footer[class*='styles_footer__'] {
	float: none;
}
body[data-device-type='mobile'] .tag-page > div:is(.tags),
body[data-device-type='tablet'] .tag-page > div:is(.tags) {
	margin: 2.25rem 0 0 var(--grid-margins);
}

/* Desktop styles */
body[data-device-type='desktop'] .category-page > div:is(.headers),
body[data-device-type='desktop'] .search-page > div:is(.headers) {
	margin: 1.5rem 0 0 var(--grid-margins);
}

body[data-device-type='desktop'] .tag-page > div:is(.tags) {
	margin: 1.5rem 0 0 var(--grid-margins);
}

body[data-device-type='desktop'] .tag-page > div:is(.cardsGrid) {
	margin: 1.5rem 0 0 var(--grid-margins);
}

body[data-device-type='desktop'] .tag-page > div.tags:has(.title) {
	margin: 3rem 0 0 var(--grid-margins);
}

body[data-device-type='desktop'] .tag-page > div:is(.view-more) {
	margin-bottom: 1.5rem;
}

body[data-device-type='desktop']:has(.ark-ui-sidebar.sidebar):not(:has(.sidebar-collapsed-hidden)) .sitemap {
	padding: 0 calc(var(--grid-margins) + 1rem);
}
body[data-device-type='desktop']:has(.ark-ui-sidebar.sidebar):not(:has(.sidebar-collapsed-hidden)) .category-page,
body[data-device-type='desktop']:has(.ark-ui-sidebar.sidebar):not(:has(.sidebar-collapsed-hidden)) .tag-page,
body[data-device-type='desktop']:has(.ark-ui-sidebar.sidebar):not(:has(.sidebar-collapsed-hidden)) .search-page {
	padding: 0 0 0 var(--grid-margins);
}

body[data-device-type='desktop']:has(.ark-ui-sidebar.sidebar):not(:has(.sidebar-collapsed-hidden))
	.category-page
	> div:not(.carousels, .cardsGrid),
body[data-device-type='desktop']:has(.ark-ui-sidebar.sidebar):not(:has(.sidebar-collapsed-hidden))
	.tag-page
	> div:not(.carousels),
body[data-device-type='desktop']:has(.ark-ui-sidebar.sidebar):not(:has(.sidebar-collapsed-hidden))
	.search-page
	> div:not(.carousels) {
	margin: 1.5rem 0 0 var(--grid-margins);
}

body[data-device-type='desktop'] main.gamepage {
	padding: calc(var(--topbar-height) + 2.5rem) var(--grid-margins) 5rem var(--grid-margins);
}

body[data-device-type='desktop']:has(.ark-ui-sidebar.sidebar):not(:has(.sidebar-collapsed-hidden)) main.gamepage {
	margin-left: 0.5rem;
}

body[data-device-type='desktop']:has(.ark-ui-topbar.topbar) main.gamepage {
	padding-top: 2.5rem;
}

body[data-device-type='desktop']:has(.ark-ui-sidebar.sidebar):not(:has(.ark-ui-topbar.topbar)) main.gamepage {
	padding-top: calc(var(--topbar-height) + 2.5rem);
}

/* Mobile and Tablet styles */
body[data-device-type='mobile'] main.gamepage,
body[data-device-type='tablet'] main.gamepage {
	padding-bottom: 5rem;
}

body[data-device-type='mobile']:has(.ark-ui-sidebar.sidebar):not(:has(.ark-ui-topbar.topbar)) main,
body[data-device-type='tablet']:has(.ark-ui-sidebar.sidebar):not(:has(.ark-ui-topbar.topbar)) main {
	padding-top: calc(var(--topbar-height));
}

/* Game Running */
body[data-device-type='mobile'].gameRunning:has(.ark-ui-topbar.topbar) main,
body[data-device-type='tablet'].gameRunning:has(.ark-ui-topbar.topbar) main,
body[data-device-type='mobile'].gameRunning:has(.ark-ui-sidebar.sidebar) main,
body[data-device-type='tablet'].gameRunning:has(.ark-ui-sidebar.sidebar) main {
	height: 100vh;
	height: 100dvh; /* Support Safari use case with browser address bar */
	padding-top: 0 !important;
}

body[data-device-type='mobile'].gameRunning:has(.ark-ui-topbar.topbar) div.data-wrapper,
body[data-device-type='tablet'].gameRunning:has(.ark-ui-topbar.topbar) div.data-wrapper,
body[data-device-type='mobile'].gameRunning:has(#sidebarToggle) div.data-wrapper,
body[data-device-type='tablet'].gameRunning:has(#sidebarToggle) div.data-wrapper {
	padding-top: 0;
}

body[data-device-type='mobile'].gameRunning .ark-ui-topbar.topbar,
body[data-device-type='tablet'].gameRunning .ark-ui-topbar.topbar,
body[data-device-type='mobile'].gameRunning #sidebarToggle,
body[data-device-type='tablet'].gameRunning #sidebarToggle,
body[data-device-type='mobile'].gameRunning .sidebarButton,
body[data-device-type='tablet'].gameRunning .sidebarButton {
	visibility: hidden;
	pointer-events: none;
}

body[data-device-type='mobile'].gameRunning:has(.ark-ui-topbar.topbar) .gamepage_game-area_game-container,
body[data-device-type='tablet'].gameRunning:has(.ark-ui-topbar.topbar) .gamepage_game-area_game-container,
body[data-device-type='mobile'].gameRunning:has(.ark-ui-sidebar.sidebar) .gamepage_game-area_game-container,
body[data-device-type='tablet'].gameRunning:has(.ark-ui-sidebar.sidebar) .gamepage_game-area_game-container {
	height: 100vh;
	height: 100dvh; /* Support Safari use case with browser address bar */
}

body[data-device-type='mobile'] .tag-page > div:is(.cardsGrid),
body[data-device-type='tablet'] .tag-page > div:is(.cardsGrid) {
	margin: 1rem 0 0 var(--grid-margins);
}

body[data-device-type='mobile'] .category-page > div:is(.cardsGrid),
body[data-device-type='tablet'] .category-page > div:is(.cardsGrid),
body[data-device-type='mobile'] .search-page > div:is(.cardsGrid),
body[data-device-type='tablet'] .search-page > div:is(.cardsGrid) {
	margin-top: 1rem;
}

/* Carousel remove periods after scroll completes */
.splide__sr {
	display: none !important;
}

.carousel:has(.splide__list:empty) {
	display: none;
}

/* Tag and Search page adjustments */
.tag-page,
.search-page {
	margin-top: 1.5rem;
	padding-right: var(--grid-margins);
}

/* Mobile and Tablet styles for Search Page */
body[data-device-type='mobile'] .search-page,
body[data-device-type='tablet'] .search-page {
	margin-top: 0;
	padding-right: 0;
}
body[data-device-type='mobile'] .search-page > div:not(.carousels, .headers, .cardsGrid),
body[data-device-type='tablet'] .search-page > div:not(.carousels, .headers, .cardsGrid) {
	margin: 0;
}

.tag-page,
.search-page,
.category-page {
	/* Adding overflow-x: hidden to specific pages since other pages has wrapper classes and the it was set to visible initially */
	overflow-x: hidden;
}

.tag-page .splide .splide__track,
.search-page .splide .splide__track {
	padding: 0 !important;
}

/* Mobile and Tablet styles for Search Page Splide */
body[data-device-type='mobile'] .search-page .splide.pills_as_slider .splide__track,
body[data-device-type='tablet'] .search-page .splide.pills_as_slider .splide__track {
	padding-left: 1rem !important;
	padding-right: 1rem !important;
	min-width: 100vw;
}

.tag-page .carousel_title,
.search-page .carousel_title {
	margin-left: 0 !important;
}

/* Disable hover on mobile devices */
/* Uncomment if needed */
/* @media (hover: none) {
    .ark-ui-block-card-standard .card:hover:before {
        all: unset !important;
    }
    .ark-ui-block-card-standard .card_hover-block {
        display: none !important;
    }
    .ark-ui-block-card-hero-big .card:hover:before {
        all: unset !important;
    }
} */
